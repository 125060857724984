import React from "react"
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import HostingPlanBlock from "../components/HostingPlans/HostingPlanBlock"
import InfoBlocks from "../components/Index/InfoBlocks"
import ClientFeedback from "../components/Index/ClientFeedback"
import Footer from "../components/App/Footer"
import BannerArea from "../components/Common/BannerArea"
import SEO from "../components/App/SEO"
import IntroHostingPlansParams from "../components/HostingPlans/IntroHostingPlansParams"
import CustomerSupportParams from "../components/Index/CustomerSupportParams"
import PromoInfoRowParams from "../components/PromoInfoRow/PromoInfoRowParams"
import SiteBenefitsParams from "../components/SiteBenefits/SiteBenefitsParams"
import FreeHosting from "../components/Common/FreeHosting";
import IntroHostingBgWhite from "../components/HostingPlans/IntroHostingBgWhite";


const GazduirePrestashopSsd = () => {
    return (
        <Layout>
            <SEO title="Găzduire PrestaShop - Sitebunker - Web Hosting pentru magazin online"
                 description="Ești în căutare de găzduire PrestaShop pentru magazinul tău online? Descoperă pachetele web hosting Sitebunker și bucură-te de viteză de încărcare premium. "
            />
            <Navbar/>


            <IntroHostingBgWhite
                title={'Resurse Premium pentru Web Hosting PrestaShop'}
                subtitle={'Alege dintre pachetele de mai jos și bucură-te de siguranța magazinului tău online și un modul premium la alegere.'}
            />

            <HostingPlanBlock category="Prestashop"
                              showPromos={true}/>

            <BannerArea
                pageTitle="Găzduire PrestaShop stabilă"
                pageSubtitle="LiteSpeed Cache Server inclus. Viteză de încărcare cu rezultate notabile"
                pageDescription="Web hosting eficient și resurse premium implementate pentru găzduirea PrestaShop necesară magazinului tău online. Ține ritmul vânzărilor online și scalează-ți business-ul cu SiteBunker."
                svgIllustration="prestashop"
            />
            <FreeHosting title='Alege pachetul de hosting ideal și ai un domeniu gratuit'
                         text1='Scalează-ți business-ul cu un hosting eficient PrestaShop Premium timp de 1 an și primești un domeniu gratuit .ro sau .eu, pentru 1 an.'
                         text2='Bucură-te de promoție și crește-ți business-ul fără griji. Oferta se aplică la pachetele Business, Enterprise, Start Plus și Enterprise Plus.'/>
            <PromoInfoRowParams
                title1="Web Hosting de încredere"
                paragraph1="99,99% UpTime, viteză de încărcare rapidă asigurată prin servere web LiteSpeed și securitate sporită inclusiv împotriva atacurilor DDoS asigurată de noua soluție implementată Arbor și protecția anti-hacking de la Imunify360. Toate incluse în pachetele disponibile la SiteBunker."
                title3="Migrare gratuită"
                paragraph3="Echipa suport SiteBunker îți asigură gratuit migrarea atunci când ai luat decizia de a schimba furnizorul de Web Hosting. Tu doar pregătește detaliile necesare migrării și contactează-ne."
                title2="Găzduire PrestaShop eficientă"
                paragraph2="Fără o găzduire PrestaShop potrivită, calitatea business-ului scade. De ce să riști când ai la dispoziție o echipă de profesioniști în web hosting? Solicită acum soluții personalizate pentru orice proiect ambițios."/>
            <InfoBlocks/>
            <ClientFeedback
                title={"Află ce spun clienții despre serviciile de "}
                bold={"web hosting SiteBunker!"}/>
            <SiteBenefitsParams
                mainTitle="GĂZDUIRE PRESTASHOP DE ÎNCREDERE"
                title1="PACHETE ACCESIBILE"
                title2="99.99% UPTIME"
                title3="SUPORT 24/7"
                text1="La SiteBunker ai pachete de găzduire PrestaShop concepute în funcție de nevoile tale. Crește performanțele magazinului tău online cu web hosting-ul potrivit."
                text2="Este important să te poți baza pe o prezență online continuă atunci când vrei să te focusezi pe aspectele legate de vânzări. Alege SiteBunker.ro, furnizorul tău pentru un web hosting stabil."
                text3="Eşti în permanență conectat cu echipa suport SiteBunker.ro. Poți deschide oricând un ticket şi îți oferim soluții imediat, indiferent de provocarea tehnică cu care te confrunți."
            />
            <CustomerSupportParams
                title="Soluții personalizate web hosting."
                paragraph1="La SiteBunker luăm în serios solicitările pentru servicii personalizate de găzduire PrestaShop şi venim în sprijinul tău cu pachete pliate nevoilor tale."
                paragraph2="Analizăm rapid cerințele tehnice necesare."
                buttonText="Contactează-ne aici."
            />
            <Footer/>
        </Layout>
    )
}

export default GazduirePrestashopSsd
